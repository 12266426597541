import React, { useState } from 'react';
import { useFirebaseOrders } from '../hooks/useFirebaseOrders';
import { X, Search, Package } from 'lucide-react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const OrderDetailsModal = ({ isOpen, onClose }) => {
  const [orderId, setOrderId] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState('');
  
  const { getOrderDetails } = useFirebaseOrders();
  
  const handleSearch = async () => {
    if (!orderId.trim()) return;
    
    setIsSearching(true);
    setError('');
    setOrderDetails(null);
    
    try {
      const details = await getOrderDetails(orderId);
      setOrderDetails(details);
      if (!details) {
        setError('Pedido não encontrado');
      }
    } catch (err) {
      console.error('Erro ao buscar pedido:', err);
      setError(err.message || 'Erro ao buscar detalhes do pedido');
    } finally {
      setIsSearching(false);
    }
  };
  
  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'Data não disponível';
    try {
      return format(timestamp.toDate(), "dd 'de' MMMM 'de' yyyy 'às' HH:mm", { locale: ptBR });
    } catch (err) {
      return 'Data inválida';
    }
  };
  
  const getStatusColor = (status) => {
    switch (status) {
      case 'Pendente': return 'bg-yellow-100 text-yellow-800';
      case 'Em Preparação': return 'bg-blue-100 text-blue-800';
      case 'A caminho': return 'bg-purple-100 text-purple-800';
      case 'Entregue': return 'bg-green-100 text-green-800';
      case 'Cancelado': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold flex items-center">
            <Package className="mr-2" /> Verificar Pedido
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={20} />
          </button>
        </div>
        
        <div className="p-6">
          {/* Search input */}
          <div className="flex items-center space-x-2 mb-6">
            <input
              type="text"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
              placeholder="Digite o identificador do pedido"
              className="flex-grow p-2 border rounded focus:ring focus:ring-blue-300 focus:border-blue-500"
            />
            <button
              onClick={handleSearch}
              disabled={isSearching || !orderId.trim()}
              className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700 disabled:bg-gray-300 flex items-center"
            >
              {isSearching ? (
                <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
              ) : (
                <>
                  <Search size={18} className="mr-1" /> Buscar
                </>
              )}
            </button>
          </div>
          
          {/* Error message */}
          {error && (
            <div className="mb-4 p-3 bg-red-100 text-red-700 rounded border border-red-300">
              {error}
            </div>
          )}
          
          {/* Order details */}
          {orderDetails && (
            <div className="bg-gray-50 p-4 rounded-lg">
              {/* Header with order ID and status */}
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold text-gray-800">
                  Pedido #{orderDetails.orderId}
                </h3>
                <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(orderDetails.status)}`}>
                  {orderDetails.status}
                </span>
              </div>
              
              {/* Two columns layout for info */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Customer info column */}
                <div>
                  <h4 className="font-semibold text-gray-700 mb-2">Informações do Cliente</h4>
                  <div className="space-y-2">
                    <p><span className="font-medium">Nome:</span> {orderDetails.customerName}</p>
                    <p><span className="font-medium">Telefone:</span> {orderDetails.customerPhone}</p>
                    <p><span className="font-medium">Endereço:</span> {orderDetails.address}</p>
                  </div>
                </div>
                
                {/* Order info column */}
                <div>
                  <h4 className="font-semibold text-gray-700 mb-2">Informações do Pedido</h4>
                  <div className="space-y-2">
                    <p><span className="font-medium">Unidade:</span> {orderDetails.pharmacyUnitId}</p>
                    <p><span className="font-medium">Vendedor:</span> {orderDetails.sellerName || 'Não informado'}</p>
                    <p><span className="font-medium">Criado em:</span> {formatDate(orderDetails.createdAt)}</p>
                    <p><span className="font-medium">Última atualização:</span> {formatDate(orderDetails.lastStatusUpdate)}</p>
                    <p><span className="font-medium">Total:</span> {orderDetails.price}</p>
                  </div>
                </div>
              </div>
              
              {/* Items section */}
              <div className="mt-6">
                <h4 className="font-semibold text-gray-700 mb-2">Itens ({orderDetails.itemCount})</h4>
                <ul className="bg-white rounded border border-gray-200 divide-y">
                  {orderDetails.items.map((item, index) => (
                    <li key={index} className="px-4 py-3">{item}</li>
                  ))}
                </ul>
              </div>
              
              {/* Status history */}
              <div className="mt-6">
                <h4 className="font-semibold text-gray-700 mb-2">Histórico de Status</h4>
                <ul className="space-y-2">
                  {orderDetails.statusHistory.map((status, index) => (
                    <li key={index} className="flex justify-between items-center p-2 bg-white rounded border border-gray-200">
                      <span className={`px-2 py-1 rounded text-sm font-medium ${getStatusColor(status.status)}`}>
                        {status.status}
                      </span>
                      <span className="text-sm text-gray-600">{formatDate(status.timestamp)}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              {/* Delivery info if available */}
              {orderDetails.isInDelivery || orderDetails.isDelivered ? (
                <div className="mt-6">
                  <h4 className="font-semibold text-gray-700 mb-2">Informações de Entrega</h4>
                  <div className="bg-white rounded border border-gray-200 p-3">
                    {orderDetails.deliveryManName && (
                      <p><span className="font-medium">Entregador:</span> {orderDetails.deliveryManName}</p>
                    )}
                    {orderDetails.licensePlate && (
                      <p><span className="font-medium">Placa do Veículo:</span> {orderDetails.licensePlate}</p>
                    )}
                    {orderDetails.reviewRequested && (
                      <div className="mt-2 pt-2 border-t">
                        <p><span className="font-medium">Avaliação:</span> {orderDetails.rating} ⭐</p>
                        {orderDetails.reviewComment && (
                          <p><span className="font-medium">Comentário:</span> "{orderDetails.reviewComment}"</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsModal;
