import { useState } from 'react';
import { collection, setDoc, doc, serverTimestamp, Timestamp, getDoc, updateDoc, addDoc, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { logOrderOperation } from '../utils/orderLogger';

export const useFirebaseOrders = (user) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  // Função para gerar um ID fácil de digitar para o pedido
  const generateEasyOrderId = async () => {
    const today = new Date();
    const prefix = `${today.getFullYear().toString().slice(2)}${(today.getMonth() + 1).toString().padStart(2, '0')}`;
    
    // Gera um número aleatório de 4 dígitos
    let uniquePart = Math.floor(1000 + Math.random() * 9000).toString();
    
    // Cria o ID completo: ano(2) + mês(2) + número aleatório(4)
    let newOrderId = `${prefix}${uniquePart}`;
    
    // Verifica se o ID já existe
    let idExists = true;
    let attempts = 0;
    const maxAttempts = 10; // Limite de tentativas para evitar loop infinito
    
    while (idExists && attempts < maxAttempts) {
      // Verifica se o ID já existe na coleção orders
      const docRef = doc(collection(db, 'orders'), newOrderId);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        // ID é único, podemos usá-lo
        idExists = false;
      } else {
        // ID já existe, gera um novo
        uniquePart = Math.floor(1000 + Math.random() * 9000).toString();
        newOrderId = `${prefix}${uniquePart}`;
        attempts++;
      }
    }
    
    if (attempts >= maxAttempts) {
      // Se não conseguirmos encontrar um ID único após várias tentativas,
      // adicionamos mais um dígito para torná-lo ainda mais único
      uniquePart = Math.floor(10000 + Math.random() * 90000).toString();
      newOrderId = `${prefix}${uniquePart}`;
    }
    
    return newOrderId;
  };

  const saveOrder = async (extractedData) => {
    if (!extractedData) return;

    setIsSaving(true);
    try {
      const timestamp = serverTimestamp();
      const currentTimestamp = Timestamp.now();
      const priceNumber = parseFloat(extractedData.totalValue.replace(',', '.'));

      // Salvamos o número do orçamento original como referência
      const originalOrderId = extractedData.orderId;

      // Gera um ID de pedido fácil de digitar e único
      const orderId = extractedData.orderId || await generateEasyOrderId();
      
      const orderData = {
        address: extractedData.address,
        createdAt: timestamp,
        customerName: extractedData.clientName,
        customerPhone: extractedData.phone,
        date: timestamp,
        orderId: orderId, // ID fácil de digitar
        originalOrderId: originalOrderId, // Número do orçamento original
        isDelivered: false,
        isInDelivery: false,
        isInPreparation: true,
        isPending: false,
        itemCount: extractedData.products.length,
        items: extractedData.products,
        lastStatusUpdate: timestamp,
        location: null,
        pharmacyUnitId: user?.unit || '',
        price: `R$ ${priceNumber.toFixed(2)}`,
        priceNumber: priceNumber,
        status: "Em Preparação",
        statusHistory: [
          { status: "Pendente", timestamp: currentTimestamp },
          { status: "Em Preparação", timestamp: currentTimestamp }
        ],
        updatedAt: timestamp,
        sellerId: user?.uid || '',
        sellerName: user?.displayName || 'Usuário não identificado'
      };

      // Log ANTES da tentativa de salvar o pedido
      await logOrderOperation('pre_create', orderId, orderData, user);
      
      try {
        // Usando o ID que geramos como ID do documento
        const docRef = doc(collection(db, 'orders'), orderId);
        await setDoc(docRef, orderData);
        
        // Log APÓS salvar com sucesso
        await logOrderOperation('created', orderId, orderData, user);
        
        return orderId;
      } catch (error) {
        // Log de erro se falhar ao salvar
        await logOrderOperation('error', orderId, orderData, user, error.message);
        throw error; // Re-lança o erro para tratamento no componente
      }
    } finally {
      setIsSaving(false);
    }
  };

  const cancelOrder = async (orderId, reason) => { 
    if (!orderId || !reason) {
      throw new Error('O motivo do cancelamento é obrigatório.');
    }

    setIsCancelling(true);
    try {
      const docRef = doc(collection(db, 'orders'), orderId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error('Pedido não encontrado');
      }

      const orderData = docSnap.data();
      const currentTimestamp = Timestamp.now();
      const timestamp = serverTimestamp();

      const updateData = {
        status: "Cancelado",
        cancelReason: reason,
        isDelivered: false,
        isInDelivery: false,
        isInPreparation: false,
        isPending: false,
        lastStatusUpdate: timestamp,
        updatedAt: timestamp,
        statusHistory: [...orderData.statusHistory, {
          status: "Cancelado",
          reason: reason,
          timestamp: currentTimestamp
        }]
      };

      // Log ANTES da tentativa de cancelar o pedido
      await logOrderOperation('pre_cancel', orderId, { ...orderData, ...updateData }, user);
      
      try {
        await updateDoc(docRef, updateData);
        
        // Log APÓS cancelar com sucesso
        await logOrderOperation('cancelled', orderId, { ...orderData, ...updateData }, user);
        
        return orderId;
      } catch (error) {
        // Log de erro se falhar ao cancelar
        await logOrderOperation('error', orderId, { ...orderData, ...updateData }, user, error.message);
        throw error;
      }
    } finally {
      setIsCancelling(false);
    }
  };

  const getOrderDetails = async (orderId) => {
    if (!orderId) return null;

    setIsFetching(true);
    try {
      const docRef = doc(collection(db, 'orders'), orderId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        return null;
      }

      return docSnap.data();
    } catch (error) {
      console.error('Erro ao buscar detalhes do pedido:', error);
      throw new Error('Erro ao buscar detalhes do pedido');
    } finally {
      setIsFetching(false);
    }
  };

  const findOrdersByCustomerInfo = async (customerName, address, phone) => {
    setIsFetching(true);
    const results = [];
    
    try {
      // Buscar pedidos recentes (últimos 30 dias)
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      
      // Buscar na coleção orders
      const ordersQuery = query(
        collection(db, 'orders'),
        orderBy('createdAt', 'desc'),
        limit(100) // Limitar a quantidade de documentos
      );
      
      const querySnapshot = await getDocs(ordersQuery);
      querySnapshot.forEach(doc => {
        const data = doc.data();
        // Verificar correspondência parcial
        const nameMatch = customerName && data.customerName && 
                         data.customerName.toLowerCase().includes(customerName.toLowerCase());
        const addressMatch = address && data.address &&
                           data.address.toLowerCase().includes(address.toLowerCase());
        const phoneMatch = phone && data.customerPhone &&
                         data.customerPhone.includes(phone);
                         
        if (nameMatch || addressMatch || phoneMatch) {
          results.push({
            id: doc.id,
            ...data
          });
        }
      });
      
      // Se não encontrar nos pedidos, buscar nos logs
      if (results.length === 0) {
        const logsQuery = query(
          collection(db, 'order_logs'),
          orderBy('timestamp', 'desc'),
          limit(100)
        );
        
        const logsSnapshot = await getDocs(logsQuery);
        logsSnapshot.forEach(doc => {
          const data = doc.data();
          const nameMatch = customerName && data.customerName && 
                           data.customerName.toLowerCase().includes(customerName.toLowerCase());
          const addressMatch = address && data.address &&
                           data.address.toLowerCase().includes(address.toLowerCase());
          
          if (nameMatch || addressMatch) {
            // Verificar se já existe este pedido nos resultados
            const exists = results.some(item => item.orderId === data.orderId);
            if (!exists) {
              results.push({
                id: data.orderId,
                orderId: data.orderId,
                customerName: data.customerName,
                address: data.address,
                fromLogs: true, // Indicador que veio dos logs
                logId: doc.id,
                timestamp: data.timestamp
              });
            }
          }
        });
      }
      
      return results;
    } catch (error) {
      console.error('Erro ao buscar pedidos por informações do cliente:', error);
      return [];
    } finally {
      setIsFetching(false);
    }
  };

  return { 
    saveOrder, 
    cancelOrder, 
    getOrderDetails, 
    findOrdersByCustomerInfo, // Nova função
    isSaving, 
    isCancelling, 
    isFetching, 
    generateEasyOrderId 
  };
};
